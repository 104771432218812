import { IconButton, Switch } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import EditableHeader from "../../../components/EditableHeader";
import { evalFieldExpression } from "../core/utils/expressions";
import { Map } from "../core/fields";
import { FieldMenuEditor } from "./FieldMenuEditor";

export type DocumentEditorProps = {
  value?: any;
  field: Field;
  general: GeneralContext;
  context: any;
  onChange: (value: any) => void;
};

export const DocumentEditor = ({
  value,
  field,
  general,
  context,
  onChange,
}: DocumentEditorProps): JSX.Element => {
  const [showPreview, setShowPreview] = useState(false);

  const [form, setForm] = useState<DocumentLayout>(
    value !== undefined && value !== null && Object.keys(value).length !== 0
      ? value
      : {
          logo: "/logo_name.png",
          headerTitle: "Prefeitura de São Paulo",
          headerDescription:
            "Secretaria de Governo Municipal, Viaduto do Chá, n° 15 - Centro Histórico de São Paulo Telefone +55 (11) 3113-8000",
          qrcode:
            "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=http://localhost:3000/subjects/b8edf4ff-09f7-41b2-92c4-b351eef07d74",
          title: field.options.title,
          description: "",
          blocks: [],
        }
  );
  const [htmlContent, setHtmlContent] = useState("``");

  useEffect(() => {
    onChange(form);
    setHtmlContent(generateContent(form, context, general));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, context, general]);

  const handleRemoveBlock = (index: number) => {
    form.blocks?.splice(index, 1);
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleAddBlock = () => {
    setForm({
      ...form,
      blocks: [
        ...form.blocks,
        {
          label: "Titulo do bloco",
          rows: [],
        },
      ],
    });
  };

  const handleSetDocumentAttr = (
    key: "headerTitle" | "headerDescription" | "title" | "description" | "logo",
    value: string
  ) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const handleSetBlockAttr = (index: number, key: "label", value: string) => {
    form.blocks[index][key] = value;
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleAddRow = (index: number) => {
    form.blocks[index].rows.push([
      { label: "Insira a etiqueta", value: "Insira o valor" },
    ]);
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleAddCol = (blockIndex: number, rowIndex: number) => {
    form.blocks[blockIndex].rows[rowIndex].push({
      label: "Insira a etiqueta",
      value: "Insira o valor",
    });
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleRemoveCol = (
    blockIndex: number,
    rowIndex: number,
    colIndex: number
  ) => {
    form.blocks[blockIndex].rows[rowIndex].splice(colIndex, 1);

    if (form.blocks[blockIndex].rows[rowIndex].length === 0) {
      form.blocks[blockIndex].rows.splice(rowIndex, 1);
    }

    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleSetColAttr = (
    blockIndex: number,
    rowIndex: number,
    colIndex: number,
    key: "label" | "value",
    value: string
  ) => {
    form.blocks[blockIndex].rows[rowIndex][colIndex][key] = value;
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const [draggingRowIndex, setDraggingRowIndex] = useState<number | null>(null);

  const handleDragRowStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    setDraggingRowIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragRowOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
    rowIndex: number
  ) => {
    e.preventDefault();
    if (draggingRowIndex === null) return;

    let newIndex = draggingRowIndex < index ? index + 1 : index;

    newIndex =
      newIndex > form.blocks[rowIndex].rows.length
        ? form.blocks[rowIndex].rows.length
        : newIndex;

    if (draggingRowIndex !== newIndex) {
      const itemToMove = form.blocks[rowIndex].rows.splice(
        draggingRowIndex,
        1
      )[0];

      if (draggingRowIndex < newIndex) {
        newIndex--;
      }

      form.blocks[rowIndex].rows.splice(newIndex, 0, itemToMove);

      setForm({
        ...form,
        blocks: form.blocks,
      });
      setDraggingRowIndex(newIndex);
    }
  };

  const handleDropRow = (e: React.DragEvent<HTMLDivElement>) => {
    setDraggingRowIndex(null);
  };

  const pdfPageStyle: React.CSSProperties = {
    width: "794px",
    height: "1123px",
    overflow: "auto",
    padding: "20px",
    boxSizing: "border-box",
    margin: "20px auto",
    backgroundColor: "white",
  };

  const wrapperStyle: React.CSSProperties = {
    width: "794px",
    height: "1123px",
    padding: "20px",
    boxSizing: "border-box",
    margin: "20px auto",
  };

  return (
    <div className="flex flex-col">
      <div className="flex space-x-10 justify-center">
        <div>
          <div className="mb-4">Mostrar prévia</div>
          <Switch
            isChecked={showPreview}
            onChange={() =>
              showPreview ? setShowPreview(false) : setShowPreview(true)
            }
            size={"lg"}
            colorScheme="red"
          />
        </div>
      </div>

      {!showPreview && (
        <div className="text-black" style={wrapperStyle}>
          <div style={pdfPageStyle}>
            <header className="flex items-center space-y-4 p-6">
              <div>
                <img src={form.logo} width="125px" alt="city logo" />
              </div>
              <div
                className="flex flex-col text-center mx-auto"
                style={{ maxWidth: "300px" }}
              >
                <EditableHeader
                  value={form.headerTitle}
                  onTextChange={(text) =>
                    handleSetDocumentAttr("headerTitle", text)
                  }
                  className="font-black text-xl"
                />
                <EditableHeader
                  value={form.headerDescription}
                  onTextChange={(text) =>
                    handleSetDocumentAttr("headerDescription", text)
                  }
                  className="text-center"
                />
              </div>
              <div>
                <img src={form.qrcode} width="100px" alt="qrcode to document" />
              </div>
            </header>

            <main className="flex flex-col space-y-4 px-6">
              <div className="flex flex-col justify-center text-center font-bold text-lg">
                <EditableHeader
                  value={form.title}
                  onTextChange={(text) => handleSetDocumentAttr("title", text)}
                  className="justify-center text-center"
                />
                <EditableHeader
                  value={form.description}
                  onTextChange={(text) =>
                    handleSetDocumentAttr("description", text)
                  }
                  className="justify-center text-center"
                />
              </div>

              <div>
                {form &&
                  form.blocks.map((block, index) => (
                    <>
                      <div className="flex items-center space-x-4 font-bold mb-2.5">
                        <div className="font-normal">
                          <FieldMenuEditor
                            field={{
                              type: "documentMap",
                              key: `block-${index}`,
                              options: {
                                layers: block.map?.layers,
                                source: block.map?.source,
                              },
                              expressions: {},
                            }}
                            onChange={(config) => {
                              form.blocks[index].map = config.options;
                              setForm({
                                ...form,
                                blocks: form.blocks,
                              });
                            }}
                          />
                        </div>

                        <EditableHeader
                          value={form.blocks[index].label}
                          onTextChange={(text) =>
                            handleSetBlockAttr(index, "label", text)
                          }
                        />
                        <IconButton
                          aria-label="Remove bloco"
                          icon={<FaTrash />}
                          onClick={() => handleRemoveBlock(index)}
                          size="sm"
                        />
                      </div>
                      <div className="flex flex-col border">
                        {block?.rows.map((row, rowIndex) => (
                          <div
                            key={"row-" + rowIndex}
                            draggable
                            onDragStart={(e) => handleDragRowStart(e, rowIndex)}
                            onDragOver={(e) =>
                              handleDragRowOver(e, rowIndex, index)
                            }
                            onDrop={handleDropRow}
                            className="flex items-center space-x-4"
                          >
                            <div className="flex w-full">
                              {row.map((col, colIndex) => {
                                return (
                                  <div className="flex w-full border">
                                    <div className="flex flex-col p-2.5">
                                      <div className="flex items-center space-x-4 text-sm text-gray-600">
                                        <EditableHeader
                                          value={col.label}
                                          onTextChange={(text) =>
                                            handleSetColAttr(
                                              index,
                                              rowIndex,
                                              colIndex,
                                              "label",
                                              text
                                            )
                                          }
                                        />
                                        <IconButton
                                          aria-label="Remove coluna"
                                          icon={<FaTrash />}
                                          onClick={() =>
                                            handleRemoveCol(
                                              index,
                                              rowIndex,
                                              colIndex
                                            )
                                          }
                                          size="sm"
                                        />
                                      </div>
                                      <EditableHeader
                                        value={col.value}
                                        onTextChange={(text) =>
                                          handleSetColAttr(
                                            index,
                                            rowIndex,
                                            colIndex,
                                            "value",
                                            text
                                          )
                                        }
                                      />
                                    </div>
                                    {colIndex + 1 === row.length && (
                                      <div className="space-x-2 ml-auto mb-auto">
                                        <IconButton
                                          aria-label="Add col"
                                          icon={<FaPlus />}
                                          onClick={() =>
                                            handleAddCol(index, rowIndex)
                                          }
                                          size="sm"
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ))}
                      </div>
                      {block.map?.source && (
                        <div className="flex justify-center pt-6">
                          <Map
                            key={"map"}
                            options={{
                              label: "",
                              layers: block.map.layers,
                              width: block.map.width ?? "300px",
                              height: block.map.height ?? "300px",
                              table: false,
                              zoomControl: false,
                              hideHeader: true,
                            }}
                            value={evalFieldExpression(
                              block.map.source,
                              context,
                              general,
                              {}
                            )}
                          />
                        </div>
                      )}
                      <div className="flex items-center space-x-4 justify-center pt-4">
                        <IconButton
                          aria-label="Add row"
                          icon={<FaPlus />}
                          onClick={() => handleAddRow(index)}
                          size="sm"
                        />
                      </div>
                    </>
                  ))}
              </div>
              <div className="flex items-center space-x-4 justify-center mt-6">
                <IconButton
                  aria-label="Add block"
                  icon={<FaPlus />}
                  onClick={() => handleAddBlock()}
                  size="sm"
                />
              </div>
            </main>
          </div>
        </div>
      )}

      {showPreview && (
        <div className="text-black" style={wrapperStyle}>
          <div
            id="pdfContent"
            style={pdfPageStyle}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        </div>
      )}
    </div>
  );
};

function generateContent(form: DocumentLayout, context: any, general: any) {
  return `
<header class="flex items-center space-y-4 p-6">
<div>
  <img src="${form.logo}" width="125px"/>
</div>
<div class="flex flex-col text-center mx-auto" style="max-width: 300px">
  <span class="font-black text-xl">${form.headerTitle}</span>
  <span>${form.headerDescription}</span>
</div>
<div>
  <img src="${form.qrcode}" width="100px"/>
</div>
</header>
<main class="flex flex-col space-y-4 px-6">
<div class="flex flex-col text-center font-bold text-lg">
  <span>${form.title}</span>
  <span>${form.description}</span>
</div>

${form.blocks
  .map((block) => {
    return `
      <div class="flex flex-col space-y-2">
        ${block.label && `<label class="font-bold">${block.label}</label>`}
    
        <div class="flex flex-col border">  
          ${block.rows
            .map((row) => {
              return `<div class="flex col">${row
                .map((col) => {
                  return `
                  <div class="flex flex-col p-2.5 border w-full">
                    <label class="text-sm text-gray-600">${col.label}</label>
                    <span>${evalFieldExpression(
                      `\`${col.value}\``,
                      context,
                      general,
                      {}
                    )}</span>
                  </div>`;
                })
                .join("\n")}</div>`;
            })
            .join("\n")}
        </div>

        ${
          !!block.map?.source
            ? `<div class="flex justify-center pt-6">
                <div class="border rounded-lg bg-gray-200" style="min-width: 300px; min-height: 300px" />
              </div>`
            : ""
        }
      </div>`;
  })
  .join("\n")}
`;
}
